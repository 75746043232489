import React, { Component } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import PostCompetencies from "../components/post-competencies";
import PostPartners from "../components/post-partners";
import PostStudents from "../components/post-students";
import PostClock from "../components/post-clock";
// import HeroImage from "../components/heroimage";
import Img from "gatsby-image";
import Layout from "../layouts";
import { rhythm } from "../utils/typography";
import { Flex, Box } from "@rebass/grid/emotion"; //https://github.com/rebassjs/grid
import { css } from "@emotion/core"; // https://github.com/gatsbyjs/gatsby/blob/master/examples/using-emotion/src/pages/index.js
// import styled from "@emotion/styled";
import {
  Padded,
  PaddedMobile,
  PageSpacing,
  PostMain,
  MarginTopPost,
  BoxShadow,
  Accent,
} from "../utils/styles";

class PostTemplate extends Component {
  render() {
    const post = this.props.data.wordpressPost;
    const students = this.props.data.allWordpressWpStudent;
    return (
      <Layout>
        <Helmet
          title={`MA User Experience Design | ${post.title}`}
          meta={[{ name: "description", content: post.content }]}
        />
        <PostMain css={MarginTopPost}>
          <Flex
            mx={[-1, -1, -2]}
            flexWrap="wrap"
            css={[PageSpacing, PaddedMobile]}
          >
            <Box width={[1, 1, 1 / 2]} px={[1, 1, 2]} key={`post-image`}>
              <h1 dangerouslySetInnerHTML={{ __html: post.title }} />
              <PostClock node={post} css={{ marginBottom: rhythm(1 / 2) }} />
            </Box>
            <Box width={[1, 1, 1 / 2]} px={[1, 1, 2]} key={`post-info`}>
              <div dangerouslySetInnerHTML={{ __html: post.excerpt }} />
            </Box>
          </Flex>
          <Flex
            mx={[-1, -1, -2]}
            flexWrap="wrap"
            css={[PageSpacing, PaddedMobile]}
          >
            <Box width={[1]} px={[0]} key={`post-image`}>
              {post.featured_media &&
                post.featured_media.localFile &&
                post.featured_media.localFile.childImageSharp && (
                  <Img
                    // css={{ marginBottom: rhythm(1) }}
                    key={
                      post.featured_media.localFile.childImageSharp.fluid.src
                    }
                    fluid={post.featured_media.localFile.childImageSharp.fluid}
                  />
                )}
            </Box>
            <Box
              width={[1]}
              px={[1, 1, 2]}
              key={`post-meta`}
              css={css`
                /* background: linear-gradient(180deg, #fff 0%, #eee 100%); */
                background: ${Accent};
                /* background: "rgb(25,225,225)"; */
                /* ${BoxShadow}; */
                margin-bottom: 0;
                @media (min-width: 40em) {
                  margin-bottom: ${rhythm(2)};
                }
                margin-top: 0;
                z-index: 10;
                color: black;
                a {
                  color: black;
                }
              `}
            >
              <Flex
                mx={[-1, -1, -2]}
                flexWrap="wrap"
                css={[
                  PageSpacing,
                  PaddedMobile,
                  css`
                    margin-bottom: ${rhythm(1)};
                    @media (min-width: 40em) {
                      margin-bottom: ${rhythm(2)};
                    }
                  `,
                ]}
              >
                <Box
                  width={[1, 1 / 3, 1 / 3]}
                  px={[1, 1, 2]}
                  key={`post-students`}
                >
                  <div css={Padded}>
                    <PostStudents
                      post={post}
                      students={students}
                      css={{ marginBottom: rhythm(1 / 2) }}
                    />
                  </div>
                </Box>
                <Box
                  width={[1, 1 / 3, 1 / 3]}
                  px={[1, 1, 2]}
                  py={[2, 0, 0]}
                  key={`post-competencies`}
                >
                  <div css={Padded}>
                    <PostCompetencies
                      node={post}
                      css={{ marginBottom: rhythm(1 / 2) }}
                    />
                  </div>
                </Box>
                <Box
                  width={[1, 1 / 3, 1 / 3]}
                  px={[1, 1, 2]}
                  py={[2, 0, 0]}
                  key={`post-partners`}
                >
                  <div css={Padded}>
                    <PostPartners
                      node={post}
                      css={{ marginBottom: rhythm(1 / 2) }}
                    />
                  </div>
                </Box>
              </Flex>
            </Box>
          </Flex>

          <Flex
            mx={[-1, -1, -2]}
            flexWrap="wrap"
            css={[PageSpacing, PaddedMobile]}
          >
            <Box width={[1, 1 / 2]} px={[1, 1, 2]} key={`post-images`}>
              {post.acf &&
                post.acf.images &&
                post.acf.images.map((picture, i) => {
                  if (picture.image) {
                    if (picture.image.localFile) {
                      const img = picture.image.localFile.childImageSharp.fluid;
                      return (
                        <div key={`${i} image-gallery`}>
                          <Img
                            css={{ marginBottom: rhythm(1) }}
                            key={img.src}
                            fluid={img}
                          />
                        </div>
                      );
                    } else {
                      return false;
                    }
                  } else {
                    return false;
                  }
                })}
            </Box>
            <Box width={[1, 1 / 2]} px={[1, 1, 2]} key={`post-content1`}>
              <div
                // css={Padded}
                dangerouslySetInnerHTML={{ __html: post.content }}
              />
            </Box>
          </Flex>
        </PostMain>
      </Layout>
    );
  }
}

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
};

export default PostTemplate;

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      content
      excerpt
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      acf {
        images {
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 680) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
      ...PostClock
      ...PostCompetencies
      ...PostPartners
      ...PostStudents
    }
    site {
      siteMetadata {
        title
        subtitle
      }
    }
    allWordpressWpStudent(sort: { fields: [title] }) {
      edges {
        node {
          title
          slug
          wordpress_id
          featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 680) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
