import { graphql } from "gatsby";
import MovLink from "../components/movlink";
import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core"; // https://github.com/gatsbyjs/gatsby/blob/master/examples/using-emotion/src/pages/index.js
import { rhythm } from "../utils/typography";
import { Accent } from "../utils/styles";

const ProjectStudents = styled.div`
  /* styles here */
  div {
    font-weight: 400;
    padding: 0;
    margin-bottom: 0.3rem;
    /* margin-bottom: ${rhythm(1 / 2)}; */
  }
`;
const AdditionalHeaderStudents = css`
  /* margin-top: ${rhythm(2)}; */
  /* color: ${Accent}; */
  color: black;
`;

const ProjectStudent = styled.div`
  /* styles here */
  div {
    font-weight: 400;
    padding: 0;
    /* margin-bottom: ${rhythm(1 / 2)}; */
  }
`;

export default ({ post, students, className = `` }) => (
  <ProjectStudents>
    {post.acf && post.acf.project_students != null && (
      <h2 css={AdditionalHeaderStudents}>Students</h2>
    )}

    {post.acf &&
      post.acf.project_students != null &&
      students.edges
        .filter(({ node }) =>
          post.acf.project_students.includes(node.wordpress_id)
        )
        .map(({ node }) => {
          return (
            <ProjectStudent key={node.wordpress_id}>
              <MovLink
                to={`/students/${node.slug}`}
                direction="left"
                // css={{ textDecoration: `none` }}
              >
                <div dangerouslySetInnerHTML={{ __html: node.title }} />
              </MovLink>
            </ProjectStudent>
          );
        })}
  </ProjectStudents>
);

export const query = graphql`
  fragment PostStudents on wordpress__POST {
    acf {
      project_students
    }
  }
`;
