import { graphql } from "gatsby";
import MovLink from "../components/movlink";
import React from "react";
import styled from "@emotion/styled";
import { rhythm } from "../utils/typography";
import { css } from "@emotion/core"; // https://github.com/gatsbyjs/gatsby/blob/master/examples/using-emotion/src/pages/index.js
import { Accent } from "../utils/styles";

const ProjectCompetencies = styled.div`
  /* styles here */
  div {
    font-weight: 400;
    padding: 0;
    margin-bottom: 0.3rem;
  }
`;
const AdditionalHeader = css`
  /* margin-top: ${rhythm(2)}; */
  /* font-weight: 300; */
    /* color: ${Accent}; */
    color: black;
  margin-top: ${rhythm(1)};
  @media (min-width: 40em) {
    margin-top: 0;
  }
`;

export default ({ node, className = `` }) => {
  if (node.categories) {
    if (
      node.categories.length > 0 &&
      node.categories[0].slug !== "uncategorized"
    ) {
      return (
        <ProjectCompetencies>
          {node.categories && <h2 css={AdditionalHeader}>Competencies</h2>}

          {node.categories &&
            node.categories
              .filter(category => category.slug !== "uncategorized")
              .map(category => (
                <div style={{ marginRight: rhythm(1) }} key={category.name}>
                  <MovLink
                    to={`/competencies/${category.slug}`}
                    direction="left"
                  >
                    {category.name}
                  </MovLink>
                </div>
              ))}
        </ProjectCompetencies>
      );
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const query = graphql`
  fragment PostCompetencies on wordpress__POST {
    categories {
      name
      slug
    }
  }
`;
