import { graphql } from "gatsby";
import React from "react";
// import { FaRegClock } from "react-icons/fa";
import { TitleMeta } from "../utils/styles";

import { rhythm } from "../utils/typography";

export default ({ node, className = `` }) => (
  <div css={{ marginTop: rhythm(-1 / 2) }} className={className}>
    <span css={TitleMeta}>
      {/* <FaRegClock 
        size={20} 
        style={{ position: `relative`, bottom: -3, color: `silver` }} 
      />
      {` `} */}
      {node.date}
    </span>
  </div>
);

export const query = graphql`
  fragment PostClock on wordpress__POST {
    date(formatString: "MMMM DD, YYYY")
  }
`;
