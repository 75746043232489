import { graphql } from "gatsby";
import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core"; // https://github.com/gatsbyjs/gatsby/blob/master/examples/using-emotion/src/pages/index.js
import { rhythm } from "../utils/typography";
import { Accent } from "../utils/styles";

const ProjectPartner = styled.div`
  /* styles here */
  div {
    font-weight: 300;
    padding: 0;
    margin-bottom: 0.3rem;
    /* margin-bottom: ${rhythm(1 / 2)}; */
  }
`;
const ProjectPartners = styled.div`
  /* styles here */
  div {
    font-weight: 400;
    padding: 0;
    margin-bottom: 0.3rem;
    /* margin-bottom: ${rhythm(1 / 2)}; */
  }
`;
const AdditionalHeader = css`
  /* margin-top: ${rhythm(2)}; */
  /* font-weight: 300; */
    /* color: ${Accent}; */
    color: black;
  /* margin-top: ${rhythm(1)}; */
  @media (min-width: 40em) {
    margin-top: 0;
  }
`;

function TitleAndUrl(data) {
  return (
    // <p>got a title</p>
    <ProjectPartner key={`partner-${data.data.project_partner_url.title}`}>
      <a
        href={data.data.project_partner_url.url}
        dangerouslySetInnerHTML={{
          __html: data.data.project_partner_url.title
        }}
        target="_blank"
        rel="noopener noreferrer"
      />
    </ProjectPartner>
  );
}

function UrlOnly(data) {
  return (
    // <p>url only</p>
    <ProjectPartner key={`partner-${data.data.project_partner_url.url}`}>
      <a
        href={data.data.project_partner_url.url}
        dangerouslySetInnerHTML={{
          __html: data.data.project_partner_url.url
        }}
        target="_blank"
        rel="noopener noreferrer"
      />
    </ProjectPartner>
  );
}

export default ({ node, className = `` }) => {
  if (node.acf.project_partner && node.acf.project_partner[0].project_partner_url) {
    return (
      <ProjectPartners>
        {node.acf && node.acf.project_partner && (
          <h2 css={AdditionalHeader}>Partners</h2>
        )}
        {node.acf &&
          node.acf.project_partner &&
          node.acf.project_partner.map((project_partner, id) => {
            // const title = project_partner.project_partner_url.title;
            if (project_partner.project_partner_url) {
              if (project_partner.project_partner_url.title) {
                return <TitleAndUrl data={project_partner} key={id} />;
              }
              return <UrlOnly data={project_partner} key={id} />;
            } else {
              return false
            }
          })}
      </ProjectPartners>
    );
  } else {
    return false
  }
};

export const query = graphql`
  fragment PostPartners on wordpress__POST {
    acf {
      project_partner {
        project_partner_url {
          title
          url
          target
        }
      }
    }
  }
`;
